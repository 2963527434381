var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect } from 'react';
export const Skeleton = (_a) => {
    var { shape, size, height, width, theme } = _a, props = __rest(_a, ["shape", "size", "height", "width", "theme"]);
    useEffect(() => { });
    return (React.createElement("puk-skeleton", Object.assign({ shape: shape, size: size, height: height, width: width, theme: theme }, props)));
};
Skeleton.defaultProps = {
    shape: 'rectangle',
    size: '40px',
    height: '40px',
    width: '40px',
    theme: 'normal'
};
