export const setUserAuth = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
};

export const removeUserAuth = () => {
    sessionStorage.removeItem("user");
};
export const getUserAuth = () => JSON.parse(sessionStorage.getItem("user")) || null;

export const isAuthenticated = () => (getUserAuth() ? true : false);

export const getIdToken = () => {
    let idToken = JSON.parse(sessionStorage.getItem("idToken")) || null;
    if (idToken) idToken = `Bearer ${idToken}`;
    return idToken;
};

export const selectedPoliciesSession = JSON.parse(
    sessionStorage.getItem("selectedPolicies")
);

export const setSelectedPoliciesSession = (policies) => {
    sessionStorage.setItem("selectedPolicies", JSON.stringify(policies));
};
export const totalPaymentSession = sessionStorage.getItem("totalPayment");

export const setTotalPaymentSession = (total) => {
    sessionStorage.setItem("totalPayment", total);
};
