import {setSsoToken} from "../../../redux/app/actions";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useRouter} from "../../../hooks";

export default function useValidateToken() {
    const dispatch = useDispatch();
    const {
        query: {ssoToken},
    } = useRouter();

    useEffect(() => {
        if (ssoToken) dispatch(setSsoToken({ssoToken}));
    }, [dispatch, ssoToken]);
}
