import "./table.scss";

import React from "react";

export default function Table({dataTestid, children, id}) {
    return (
        <table id={id} data-testid={dataTestid} className="animated fadeIn">
            {children}
        </table>
    );
}
