import {
    AUTHORIZE_TRANSACTION_REQUEST,
    AUTHORIZE_TRANSACTION_RESPONSE,
    CLEAR_SESSION_PAYMENT,
    GET_CODE_PAGO_EFECTIVO_REQUEST,
    GET_CODE_PAGO_EFECTIVO_RESPONSE,
    GET_SESSION_VISA_REQUEST,
    GET_SESSION_VISA_RESPONSE,
} from "./payment-form.constants";

import {ERROR_VISA} from "../../constants/generic-message-type";
import {handleActions} from "redux-actions";
import initialState from "./payment-form.state";

const PaymentFormReducer = handleActions(
    {
        [GET_SESSION_VISA_REQUEST]: (state) => ({
            ...state,
            session: {
                loading: true,
                error: null,
            },
        }),
        [GET_SESSION_VISA_RESPONSE]: {
            next: (state, {payload}) => ({
                ...state,
                session: {
                    ...payload,
                    loading: false,
                },
            }),
            throw: (state, {payload}) => ({
                ...state,
                session: {
                    loading: false,
                    error: ERROR_VISA.content,
                },
            }),
        },
        [GET_CODE_PAGO_EFECTIVO_REQUEST]: (state) => ({
            ...state,
            pagoEfectivo: {
                ...state.pagoEfectivo,
                loading: true,
                error: null,
            },
        }),
        [GET_CODE_PAGO_EFECTIVO_RESPONSE]: {
            next: (state, {payload}) => ({
                ...state,
                pagoEfectivo: {
                    cipCode: payload.cip,
                    cipUrl: payload.cipUrl,
                    loading: false,
                    success: true,
                    error: null,
                },
            }),
            throw: (state, {payload}) => ({
                ...state,
                pagoEfectivo: {
                    loading: false,
                    error: ERROR_VISA.content,
                },
            }),
        },
        [AUTHORIZE_TRANSACTION_REQUEST]: (state) => ({
            ...state,
            transaction: {
                ...state.transaction,
                loading: true,
            },
            session: {
                ...state.session,
                error: null,
            },
        }),
        [AUTHORIZE_TRANSACTION_RESPONSE]: {
            next: (state, {payload}) => ({
                ...state,
                session: {
                    ...state.session,
                    error: null,
                },
                transaction: {
                    success: true,
                    error: null,
                    loading: false,
                    data: payload,
                },
            }),
            throw: (state, {payload}) => ({
                ...state,
                session: {
                    ...state.session,
                    loading: false,
                },
                transaction: {
                    success: false,
                    loading: false,
                    error: payload.response,
                    data: null,
                },
            }),
        },
        [CLEAR_SESSION_PAYMENT]: (state) => initialState,
    },
    initialState
);

export default PaymentFormReducer;
