var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable no-param-reassign */
import React from 'react';
export const NavBar = (_a) => {
    var { sticky, layoutWidth, className, children } = _a, props = __rest(_a, ["sticky", "layoutWidth", "className", "children"]);
    return React.createElement("puk-navbar", Object.assign({}, props, { customclass: className }), children);
};
NavBar.defaultProps = {
    sticky: true,
    layoutWidth: 980,
};
