import { Auth } from "@pacificoseguros/tools";
import { v4 as uuidv4 } from 'uuid';
import { ENV } from "../constants/enviroment";
import { ajax } from "rxjs/ajax";
import { getIdToken } from "../libs/helpers/auth.helper";
import { stringify } from "querystringify";

class Api {
    static getUrl(url, host) {
        return `${host}/${url}`;
    }

    static getHeader() {
        return {
            "Content-Type": "application/json",
        };
    }

    static getHeaderProductoSoatUX() {
        return {
            ...Api.getPrivateHeader(),
            "Ocp-Apim-Subscription-Key": `${ENV.SUBSCRIPTION_KEY_UX}`,
            "STS-id-token": getIdToken(),
            "Canal-Id": "",
        };
    }
    static getHeaderProductoSoatSP() {
        return {
            ...Api.getPrivateHeader(),
            "Ocp-Apim-Subscription-Key": `${ENV.SUBSCRIPTION_KEY_SP}`,
        };
    }

    static getPrivateHeader() {
        return {
            "Content-Type": "application/json",
            Authorization: Auth.getAuthorization(),
            "Id-Transaction": uuidv4(),
            "Business-Process": "soporte-ti",
            "Application-Name": "Backoffice-SOAT",
            "Seller-Id": sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")).idCorredor : 0
        };
    }

    static get(
        url,
        { params = {}, isPublicRequest = true, host = ENV.SERVER_URL, customHeader = null }
    ) {
        return ajax.get(
            Api.getUrl(`${url}?${stringify(params)}`, host),
            customHeader
                ? customHeader
                : isPublicRequest
                    ? Api.getHeader()
                    : Api.getPrivateHeader()
        );
    }

    static post(
        url,
        {
            params = {},
            data = {},
            isPublicRequest = true,
            host = ENV.SERVER_URL,
            customHeader = null,
        }
    ) {
        return ajax.post(
            Api.getUrl(`${url}?${stringify(params)}`, host),
            data,
            customHeader
                ? customHeader
                : isPublicRequest
                    ? Api.getHeader()
                    : Api.getPrivateHeader()
        );
    }
}
export default Api;
