import { catchError, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";

import AuthApi from "../../api/auth.api";
import { POST_VALIDATE_SSO_TOKEN_REQUEST } from "./auth.constants";
import { of } from "rxjs";
import { postValidateSsoTokenResponse } from "./auth.actions";
import { setUserAuth } from "../../libs/helpers/auth.helper";

export const postValidateSsoTokenRequestEpic = (action$) =>
    action$.pipe(
        ofType(POST_VALIDATE_SSO_TOKEN_REQUEST),
        switchMap(({ payload }) =>
            AuthApi.validateSsoToken(payload.ssoToken).pipe(
                switchMap(({ response }) =>
                    AuthApi.validateIdToken(response.idToken).pipe(switchMap(({ response: responseValidToken }) => {

                        try {
                            if (responseValidToken) setUserAuth(responseValidToken);
                            sessionStorage.setItem(
                                "idToken",
                                JSON.stringify(response.idToken)
                            );
                        } catch (error) { }

                        return of(
                            postValidateSsoTokenResponse({ idToken: response.idToken, user: responseValidToken })
                        );
                    }),
                        catchError((error) => of(postValidateSsoTokenResponse(error)))

                    )

                ),
                catchError((error) => of(postValidateSsoTokenResponse(error)))
            )
        )
    );

export const AuthEpics = combineEpics(postValidateSsoTokenRequestEpic);
