var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable no-param-reassign */
import React from 'react';
export const Button = (props) => {
    const { children, kind, small, big, outline, inverse, ghost, fluid, noShadow, className, asLink, target, customClass = "" } = props, nativeProps = __rest(props, ["children", "kind", "small", "big", "outline", "inverse", "ghost", "fluid", "noShadow", "className", "asLink", "target", "customClass"]);
    return (React.createElement("puk-button", Object.assign({ kind: kind, small: small, big: big, outline: outline, inverse: inverse, ghost: ghost, fluid: fluid, customclass: className, noshadow: noShadow, asLink: asLink, target: target }, nativeProps), children));
};
Button.defaultProps = {
    asLink: false,
    big: false,
    fluid: false,
    ghost: false,
    inverse: false,
    kind: '',
    outline: false,
    small: false,
    noShadow: false,
    target: '',
    type: 'button'
};
