/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
export const Tab = function ({ name, title, icon, className, }) {
    return (React.createElement("puk-tabs-tab", { name: name, customclass: className },
        icon ? React.cloneElement(icon, { slot: 'icon' }) : null,
        React.createElement("span", { slot: "title" }, title)));
};
export const TabHeader = function ({ compact, children }) {
    return (React.createElement("puk-tabs-header", { compact: compact }, children));
};
export const TabContent = function ({ target, children }) {
    return React.createElement("puk-tabs-content", { for: target }, children);
};
/**
 * TabBody component
 */
export const TabBody = function ({ children }) {
    return React.createElement("puk-tabs-body", null, children);
};
export const Tabs = function ({ activeTab, children, onChange, className, }) {
    const wcRef = useRef();
    useEffect(function () {
        wcRef.current.addEventListener('tabs:change', onChange);
        return function () {
            if (wcRef.current) {
                wcRef.current.removeEventListener('tabs:change', onChange);
            }
        };
    }, []);
    return (React.createElement("puk-tabs", { ref: wcRef, activeTab: activeTab, customclass: className }, children));
};
Tabs.defaultProps = {
    onChange() {
        /* Implemented by user */
    }
};
