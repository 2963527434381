var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useRef } from 'react';
export const DatePicker = (props) => {
    const ref = useRef();
    const { format, value = '', // not use this shit
    minDate, maxDate, defaultDate, disabledRanges = [], allowInput, onClose, onOpen, onChange, className, disableMobile, 
    // textfield props
    raised, invalid, small, big, fluid, iconAtRight, loading, icon } = props, nativeProps = __rest(props, ["format", "value", "minDate", "maxDate", "defaultDate", "disabledRanges", "allowInput", "onClose", "onOpen", "onChange", "className", "disableMobile", "raised", "invalid", "small", "big", "fluid", "iconAtRight", "loading", "icon"]);
    const open = () => onOpen();
    const close = (ev) => onClose(ev.detail);
    const select = (ev) => {
        if (ev.detail) {
            onChange(...ev.detail);
        }
    };
    useEffect(() => {
        const webc = ref.current;
        if (webc) {
            webc.getInstance().then(flatpickr => {
                if (value && flatpickr) {
                    flatpickr.setDate(value, true, "Y-m-d");
                }
            });
        }
    }, [value]);
    useEffect(() => {
        ref.current.defaultDate = defaultDate;
    }, [defaultDate]);
    useEffect(() => {
        ref.current.minDate = minDate;
    }, [minDate]);
    useEffect(() => {
        ref.current.maxDate = maxDate;
    }, [maxDate]);
    useEffect(() => {
        const datePicker = ref.current;
        datePicker.addEventListener('datepicker:open', open);
        datePicker.addEventListener('datepicker:close', close);
        datePicker.addEventListener('datepicker:select', select);
        return () => {
            datePicker.removeEventListener('datepicker:open', open);
            datePicker.removeEventListener('datepicker:close', close);
            datePicker.removeEventListener('datepicker:select', select);
            if (datePicker) {
                datePicker.destroy && datePicker.destroy();
            }
        };
    }, []);
    useEffect(() => {
        // Inject disabled ranges into web component
        disabledRanges.forEach((range) => {
            if (ref.current) {
                ref.current.disableRangeBetween(range);
            }
        });
    }, [disabledRanges]);
    return (React.createElement("puk-datepicker", { raised: raised, invalid: invalid, small: small, big: big, fluid: fluid, loading: loading, iconAtRight: iconAtRight, format: format, allowInput: allowInput, customclass: className, ref: ref },
        React.createElement("input", Object.assign({}, nativeProps, { type: "date", slot: "input" })),
        icon ? React.cloneElement(icon, { 'data-icon': '' }) : null,
        React.createElement("div", { className: "loadingAnimation" },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
};
DatePicker.defaultProps = {
    onOpen: () => { },
    onClose: () => { },
    onChange: () => { },
    allowInput: false,
    format: 'd/m/Y',
    minDate: null,
    maxDate: null,
    defaultDate: null,
    disabledRanges: [],
    loading: false,
    big: false,
    small: false,
    raised: false,
    invalid: false,
    className: '',
};
