import { convertDateToString, isEmpty } from "../../../libs/utils";

export const getPoliciesMapping = (dataPolicy) => {

    if (isEmpty(dataPolicy.policies) && dataPolicy.policies.length < 1) {
        return {
            policies: [],
            policiesAll: [],
            pagination: {
                currentPage: 0,
                itemsPerPage: 10,
                totalPages: 0,
                totalElements: 0,
                pageToGo: 0,
                isPagination: true
            }
        };
    }

    const transformPolicies = dataPolicy.policies.map((rowPolicy) => {
        const quotationDateFormat = convertDateToString(rowPolicy.quotationDate);
        const paymentDateFormat = convertDateToString(rowPolicy.paymentDate);
        const generationDateFormat = convertDateToString(rowPolicy.generationDate);
        return {

            id: rowPolicy.id,
            policy: rowPolicy.policy,
            licensePlate: rowPolicy.licensePlate,
            sellerUser: rowPolicy.sellerUser,
            paymentType: rowPolicy.paymentType,
            paymentStatus: rowPolicy.paymentStatus,
            quotationDate: rowPolicy.quotationDate,
            discountCode: rowPolicy.discountCode,
            channel: rowPolicy.channel,
            policyStatus: rowPolicy.policyStatus,
            price: rowPolicy.price,
            paymentMethod: rowPolicy.paymentMethod,
            transactionNumber: rowPolicy.transactionNumber,
            generationDate: rowPolicy.generationDate,
            paymentDate: rowPolicy.paymentDate,
            cipCode: rowPolicy.cipCode,
            quotationDateFormat,
            generationDateFormat,
            paymentDateFormat
        };
    });

    return {
        policies: dataPolicy.pagination.paged ? transformPolicies : [],
        policiesAll: dataPolicy.pagination.paged ? [] : transformPolicies,
        pagination: dataPolicy.pagination.paged ? {
            currentPage: dataPolicy.pagination.pageActual,
            itemsPerPage: dataPolicy.pagination.itemsPerPage,
            totalPages: dataPolicy.pagination.totalPages,
            totalElements: dataPolicy.pagination.totalElements,
            pageToGo: 0,
            isPagination: dataPolicy.pagination.paged
        } : null
    }
};