import AppReducer from "./app/reducer";
import AuthReducer from "./auth/auth.reducer";
import PaymentFormReducer from "./payment-form/payment-form.reducer";
import PaymentsReducer from "./payments/payments.reducer";
import {combineReducers} from "redux";

export default combineReducers({
    AppReducer,
    PaymentsReducer,
    AuthReducer,
    PaymentFormReducer,
});
