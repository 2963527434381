var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-namespace,react/prop-types */
import React, { createRef, useEffect } from 'react';
export const Toggle = (_a) => {
    var { inactiveContent, activeContent, onToggle, className } = _a, props = __rest(_a, ["inactiveContent", "activeContent", "onToggle", "className"]);
    const toggleRef = createRef();
    useEffect(() => {
        toggleRef.current.addEventListener('toggle:change', onToggle);
        return () => {
            if (toggleRef.current) {
                toggleRef.current.removeEventListener('toggle:change', onToggle);
            }
        };
    }, []);
    return (React.createElement("puk-toggle", Object.assign({}, props, { customclass: className, ref: toggleRef }),
        React.cloneElement(inactiveContent, { slot: 'inactive' }),
        React.cloneElement(activeContent, { slot: 'active' })));
};
Toggle.defaultProps = {
    onToggle: () => { }
};
