import "./icon.scss";

import React, {memo} from "react";

export const Icon = ({name = "error", height, width, size, className, ...props}) => {
    const icon = require(`../../assets/images/icons/${name}.svg`);

    return (
        <img
            title={`icono ${name}`}
            className={className}
            src={icon}
            alt={`icon-${name}`}
            {...props}
        />
    );
};

export default memo(Icon);
