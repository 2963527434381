import { GET_CREDIT_LINE_REQUEST, GET_POLICIES_REQUEST } from "./payments.constants";
import { catchError, switchMap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { getCreditLineResponse, getPoliciesResponse } from "./payments.actions";

import CreditLineApi from "../../api/credit-line.api";
import PoliciesApi from "../../api/policies.api";
import { of } from "rxjs";
import { getPoliciesMapping } from "../../containers/payments/mappings/payments.mapping";

export const getPoliciesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(GET_POLICIES_REQUEST),
        switchMap(({ payload = {} }) =>
            PoliciesApi.getPolicies(payload.params).pipe(
                switchMap(({ response }) => {
                    let policiesMapping = getPoliciesMapping(response);
                    const actions = { ...state$.value.PaymentsReducer.actions, ...payload.actions };

                    return of(getPoliciesResponse({
                        ...policiesMapping, uiLoading: { status: false, message: "" },
                        actions
                    }));

                }),
                catchError((error) => of(getPoliciesResponse(error)))
            )

        )
    );

export const getCreditLineRequestEpic = (action$) =>
    action$.pipe(
        ofType(GET_CREDIT_LINE_REQUEST),
        switchMap(() =>
            CreditLineApi.getCreditLine().pipe(
                switchMap(({ response }) => of(getCreditLineResponse(response))),
                catchError((error) => of(getCreditLineResponse(error)))
            )
        )
    );

export const PaymentsEpics = combineEpics(
    getPoliciesRequestEpic,
    getCreditLineRequestEpic
);
