import "./generic-message.scss";

import React, {memo} from "react";

import {ERROR_MESSAGE} from "../../constants/generic-message-type";
import Icon from "../icon/icon";
import PropTypes from "prop-types";

const GenericMessage = ({children, type}) => {
    return (
        <div data-testid="generic-message" className="generic-message animated fadeIn">
            <Icon slot="icon" name={type.icon} size={90} />
            <div className="generic-message__title">{type.title}</div>
            <p className="generic-message__content">{type.content}</p>
            <div className="generic-message__footer">{children}</div>
        </div>
    );
};

GenericMessage.propTypes = {
    type: PropTypes.object.isRequired,
};

GenericMessage.defaultProps = {
    type: ERROR_MESSAGE,
};

export default memo(GenericMessage);
