var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export const Wizard = (_a) => {
    var { cyan, length, current, className } = _a, props = __rest(_a, ["cyan", "length", "current", "className"]);
    return (React.createElement("puk-wizard", Object.assign({ length: length, current: current, cyan: cyan, customclass: className }, props)));
};
Wizard.defaultProps = {
    length: 2,
    current: 1,
    cyan: false,
};
