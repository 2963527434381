import React, { useEffect, createRef } from 'react';
export const Switch = function ({ on, onToggle, className, }) {
    const ref = createRef();
    useEffect(function () {
        ref.current.addEventListener('switch:toggle', onToggle);
        return function () {
            if (ref.current) {
                ref.current.removeEventListener('switch:toggle', onToggle);
            }
        };
    }, []);
    return React.createElement("puk-switch", { on: on, customclass: className, ref: ref });
};
Switch.defaultProps = {
    on: false,
    onToggle: () => { }
};
