import "./pagination.scss";

import { Button, TextField } from "@pacificoseguros/pixie/react";
import React, { memo, useState } from "react";

const Pagination = (props) => {
    const {
        pageNumbers,
        currentPage,
        nextPage,
        previousPage,
        goToPage,
        error,
    } = props;

    const [pageToGo, setPageToGo] = useState(currentPage);

    return (
        <div data-testid="pagination" className="pagination">
            <div className="d-flex justify-content-between align-items-center">
                <TextField
                    data-testid="pagination-input"
                    invalid={error}
                    className="pagination__input"
                    value={pageToGo}
                    small
                    placeholder="Ej: 1"
                    onChange={({ target }) => {
                        if (isNaN(target.value)) return;
                        setPageToGo(target.value);
                    }}
                />
                <Button kind="secondary" inverse onClick={() => goToPage(pageToGo)}>
                    Ir a la página
                </Button>
            </div>
            <ul className="pagination__list">
                <li className="pagination__item mx-5">
                    <button
                        data-testid="previous-button"
                        onClick={previousPage}
                        className="pagination__button"
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                </li>
                <p>
                    Página {currentPage} de {pageNumbers.length}
                </p>
                <li className="pagination__item mx-5">
                    <button
                        data-testid="next-button"
                        onClick={nextPage}
                        className="pagination__button"
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default memo(Pagination);
