import {
    CLEAR_ERROR_PAYMENTS,
    GET_CREDIT_LINE_REQUEST,
    GET_CREDIT_LINE_RESPONSE,
    GET_POLICIES_REQUEST,
    GET_POLICIES_RESPONSE,
    SET_TOTAL_PAYMENT,
    SET_UI_LOADING,
} from "./payments.constants";

import {createActions} from "redux-actions";

const actionsCreator = createActions(
    {},
    GET_POLICIES_RESPONSE,
    GET_POLICIES_REQUEST,
    GET_CREDIT_LINE_REQUEST,
    GET_CREDIT_LINE_RESPONSE,
    SET_TOTAL_PAYMENT,
    CLEAR_ERROR_PAYMENTS,
    SET_UI_LOADING
);
export const {
    getPoliciesResponse,
    getPoliciesRequest,
    getCreditLineRequest,
    getCreditLineResponse,
    setTotalPayment,
    clearErrorPayments,
    setUiLoading,
} = actionsCreator;
