import {CustomModal, GenericMessage} from "..";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../constants/generic-message-type";
import React, {memo} from "react";

import {Button} from "@pacificoseguros/pixie/react";
import PropTypes from "prop-types";

const GenericModal = ({type, isOpen, onClose, children}) => {
    return (
        <>
            {isOpen && (
                <CustomModal data-testid="custom-modal" isOpen={isOpen} onClose={onClose}>
                    <GenericMessage type={type}>
                        {children}
                        <Button
                            kind={
                                type.icon === SUCCESS_MESSAGE.icon ? "primary" : "warning"
                            }
                            fluid
                            onClick={onClose}
                        >
                            Cerrar
                        </Button>
                    </GenericMessage>
                </CustomModal>
            )}
        </>
    );
};

GenericModal.propTypes = {
    type: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

GenericModal.defaultProps = {
    type: ERROR_MESSAGE,
    isOpen: false,
    onClose: () => {},
};
export default memo(GenericModal);
