import "./styles/app.scss";

import AppRoutes from "./app.routes";
import { ENV } from "../../constants/enviroment";
import { LoadingOverlay } from "../../components";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "../../redux/store";
import { useOauth } from "../../hooks";

function App() {
    const done = useOauth(ENV.OAUTH_SP_RESOURCE);

    useEffect(() => {
        sessionStorage.removeItem("selectedPolicies");
        sessionStorage.removeItem("totalPayment");
    }, []);

    return done ? (
        <div data-testid="app" className="app">
            <Provider store={store}>
                <Router basename="/soat">
                    <AppRoutes />
                </Router>
            </Provider>
        </div>
    ) : (
        <LoadingOverlay show />
    );
}

export default App;
