import "./navbar.scss";

import {Button} from "@pacificoseguros/pixie/react";
import {Container} from "@material-ui/core";
import React from "react";
import logoImage from "./../../../assets/images/logo-pacifico.png";

const PublicNavbar = () => {
    return (
        <div className="navbar__public">
            <h3>Easy SOAT</h3>
        </div>
    );
};

const PrivateNavbar = ({logout}) => {
    return (
        <div className="navbar__private">
            <img
                src={logoImage}
                title="Logo Pacifico Seguros"
                alt="Logo Pacífico Seguros"
                width="200"
            />
            <Button
                data-testid="logout"
                onClick={() => {
                    window.location.href = "https://www.pacifico.com.pe";
                    logout();
                }}
            >
                Cerrar sesion
            </Button>
        </div>
    );
};

export default function Navbar({isAuth, logout}) {
    return (
        <nav data-testid="navbar" className="navbar">
            <Container>
                {isAuth() ? <PrivateNavbar logout={logout} /> : <PublicNavbar />}
            </Container>
        </nav>
    );
}
