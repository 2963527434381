export const ENV = {
    NODE_ENV: process.env.NODE_ENV,
    SERVER_URL: process.env.REACT_APP_BASE_URL,

    OAUTH_URL: process.env.REACT_APP_OAUTH_URL,
    OAUTH_SP_RESOURCE: process.env.REACT_APP_OAUTH_RESOURCE_SOAT_SP,
    OAUTH_UX_RESOURCE: process.env.REACT_APP_OAUTH_RESOURCE_SOAT_UX,

    SUBSCRIPTION_KEY_SP: process.env.REACT_APP_SUBSCRIPTION_KEY_SOAT_SP,
    SUBSCRIPTION_KEY_UX: process.env.REACT_APP_SUBSCRIPTION_KEY_SOAT_UX,
    MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID,

    URL_MS_SP_ASEGURA_SESION: process.env.REACT_APP_MS_SP_ASEGURA_SESION,
    URL_MS_UX_GESTION_POLIZAS: process.env.REACT_APP_MS_UX_GESTION_POLIZAS,
    URL_MS_NE_ACTUALIZA_POLIZAS: process.env.REACT_APP_MS_NE_ACTUALIZA_POLIZAS,
    URL_MS_NE_CONSULTA_POLIZAS: process.env.REACT_APP_MS_NE_CONSULTA_POLIZAS,
    URL_MS_NE_PROCESA_PAGO_POLIZAS: process.env.REACT_APP_MS_NE_PROCESA_PAGO_POLIZAS,
    URL_MS_UX_PROCESA_PAGO_POLIZAS: process.env.REACT_APP_MS_UX_PROCESA_PAGO_POLIZAS,
    URL_MS_UX_ACTUALIZA_LINEA_CREDITO:
        process.env.REACT_APP_MS_UX_ACTUALIZA_LINEA_CREDITO,
};
