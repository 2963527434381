import React from "react";
import classnames from "classnames";

export default function TableRow({isSelected, children}) {
    return (
        <tr
            className={classnames({
                "selected-row": isSelected,
            })}
        >
            {children}
        </tr>
    );
}
