export const isValidEmail = (elementValue) => {
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/;
    return emailPattern.test(elementValue);
};

export const isEmptyString = (string) =>
    string === undefined || string === null || string.trim() === "";

export const isEmpty = (n) => {
    return !(!!n
        ? typeof n === "object"
            ? Array.isArray(n)
                ? !!n.length
                : !!Object.keys(n).length
            : true
        : false);
};

export const isAlphanumeric = (string) => {
    const regex = /^[a-zA-Z0-9_]*$/;
    return string.match(regex) || string === "";
};

export const convertDateToString = (date) => {
    if (!date) return "";
    date = new Date(date);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = date.getFullYear();
    return dd + "-" + mm + "-" + yyyy;
};

export const convertStringToDate = (dateString) => {
    if (!dateString) return null;

    let dateParts = dateString.split("-");
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
};

export const concatQuotationMark = (initialValue) => {
    const newValue = [];

    if (!initialValue) return newValue;

    initialValue.forEach((value) => {
        const val = { ...value };
        newValue.push(val);
    });

    newValue.forEach((value) => {
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                value[key] = value[key] !== null ? `'${value[key]}'` : "";
            }
        }
    });

    return newValue;
};

export const addFormattedDatesToPolicies = (dataPolicy) => {
    if (isEmpty(dataPolicy.policies) && dataPolicy.policies.length < 1) return dataPolicy;

    dataPolicy.policies = dataPolicy.policies.map((policy) => {
        const quotationDateFormat = convertDateToString(policy.quotationDate);
        const paymentDateFormat = convertDateToString(policy.paymentDate);
        const generationDateFormat = convertDateToString(policy.generationDate);

        return {
            ...policy,
            quotationDateFormat,
            generationDateFormat,
            paymentDateFormat,
        };
    });
    return dataPolicy;
};

export const resetHoursDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
};

export const hasDuplicates = (array) => {
    return new Set(array).size !== array.length;
};
