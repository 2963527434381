var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
const Typography = (_a) => {
    var { color, size, unit, align, bold, children, tag, className, style } = _a, props = __rest(_a, ["color", "size", "unit", "align", "bold", "children", "tag", "className", "style"]);
    return (React.createElement("puk-typography", { color: color, size: size, unit: unit, align: align, bold: bold, tag: tag, customclass: className }, children));
};
Typography.defaultProps = {
    size: 16,
    unit: 'px',
    color: '#444',
    align: 'left',
    bold: false,
};
export { Typography };
