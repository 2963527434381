import Api from "./api";
import {ENV} from "../constants/enviroment";
import {map} from "rxjs/operators";

export default class PaymentApi extends Api {
    static getSessionToken(requestSessionToken) {
        return super.post(
            `${ENV.URL_MS_UX_PROCESA_PAGO_POLIZAS}/polizas/transacciones/sesion`,
            {
                isPublicRequest: false,
                data: JSON.stringify(requestSessionToken),
                customHeader: {
                    ...Api.getHeaderProductoSoatUX(),
                },
            }
        );
    }

    static validateTransaction(order) {
        return super.post(
            `${ENV.URL_MS_UX_PROCESA_PAGO_POLIZAS}/polizas/transacciones/${order.purchaseNumber}`,
            {
                isPublicRequest: false,
                data: JSON.stringify(order),
                customHeader: {
                    ...Api.getHeaderProductoSoatUX(),
                },
            }
        );
    }

    static getCipCode(order) {
        return super
            .post(
                `${ENV.URL_MS_UX_PROCESA_PAGO_POLIZAS}/polizas/transacciones/generar-codigo`,
                {
                    isPublicRequest: false,
                    data: JSON.stringify(order),
                    customHeader: {
                        ...Api.getHeaderProductoSoatUX(),
                    },
                }
            )
            .pipe(map((resp) => resp.response));
    }
}
