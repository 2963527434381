import {
    POST_VALIDATE_SSO_TOKEN_REQUEST,
    POST_VALIDATE_SSO_TOKEN_RESPONSE,
    SET_USER,
} from "./auth.constants";

import {handleActions} from "redux-actions";
import initialState from "./auth.state";

const AuthReducer = handleActions(
    {
        [SET_USER]: (state, {payload}) => ({
            ...state,
            user: payload,
        }),
        [POST_VALIDATE_SSO_TOKEN_REQUEST]: (state) => ({
            ...state,
            loading: true,
            error: null,
        }),
        [POST_VALIDATE_SSO_TOKEN_RESPONSE]: {
            next: (state, {payload}) => ({
                ...state,
                loading: false,
                idToken: payload.idToken,
                user: payload.user,
            }),
            throw: (state, {payload}) => ({
                ...state,
                loading: false,
                error: payload,
            }),
        },
    },
    initialState
);

export default AuthReducer;
