var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { createRef, forwardRef, useEffect } from 'react';
const MaskField = forwardRef((_a, ref) => {
    var { maskType, maskPattern, thousandGroupStyle, blocks, prefix, className, uppercase, delimiter, delimiters, numericOnly, onValueChange, raised, clearable, loading, invalid, small, big, fluid, icon, iconAtRight, onChange } = _a, props = __rest(_a, ["maskType", "maskPattern", "thousandGroupStyle", "blocks", "prefix", "className", "uppercase", "delimiter", "delimiters", "numericOnly", "onValueChange", "raised", "clearable", "loading", "invalid", "small", "big", "fluid", "icon", "iconAtRight", "onChange"]);
    const wcRef = createRef();
    useEffect(function () {
        if (ref && !ref.current) {
            // eslint-disable-next-line no-param-reassign
            ref.current = wcRef.current;
        }
        wcRef.current.addEventListener('maskfield:change', onValueChange);
        return function () {
            wcRef.current.removeEventListener('maskfield:change', onValueChange);
        };
    }, []);
    useEffect(function () {
        wcRef.current.delimiters = delimiters;
    }, [delimiters]);
    useEffect(function () {
        wcRef.current.blocks = blocks;
    }, [blocks]);
    useEffect(function () {
        wcRef.current.maskPattern = maskPattern;
    }, [maskPattern]);
    return (React.createElement("puk-maskfield-old", { raised: raised, loading: loading, invalid: invalid, small: small, clearable: clearable, big: big, fluid: fluid, class: className, prefix: prefix, uppercase: uppercase, delimiter: delimiter, numericOnly: numericOnly, ref: wcRef },
        React.createElement("input", Object.assign({ slot: "input" }, props)),
        icon ? React.cloneElement(icon, { slot: 'icon' }) : null));
});
MaskField.defaultProps = {
    blocks: [],
    prefix: '',
    uppercase: false,
    delimiter: '',
    delimiters: [],
    numericOnly: false,
    defaultValue: '',
    onValueChange: () => { }
};
export { MaskField };
