// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../react/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16i{display:inline-block;position:relative}._16i ._3f-{position:relative;display:inline-flex;justify-content:center;align-items:center;overflow:hidden}._16i ._3f- ._1P3{position:absolute;transition:.6s ease;transform:translate(-100%)}._16i ._3f- ._1P3._3Zx{transform:translate(0)}._16i ._3f- ._1P3._3Zx~._1P3{transform:translate(100%)}._16i ._1BG,._16i ._1wv{background-size:contain!important;cursor:pointer;top:50%;position:absolute;height:16px;width:12px;opacity:.65;transition:opacity .2s ease-in-out;transform:translateY(calc(-50% - 16px));z-index:2}._16i ._1BG:hover,._16i ._1wv:hover{opacity:1}._16i ._1BG{background:url(\"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC4zMzQiIGhlaWdodD0iMzUiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCA0NjkiIGQ9Ik0yMi44MzQgMS41TDEuNSAxNy41bDIxLjMzNCAxNiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iNSIvPjwvc3ZnPg==\") no-repeat;left:-12px}._16i ._1wv{background:url(\"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC4zMzQiIGhlaWdodD0iMzUiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCA0NzEiIGQ9Ik0xLjUgMzMuNTAxbDIxLjMzNC0xNi0yMS4zMzQtMTYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjUiLz48L3N2Zz4=\") no-repeat;right:-12px}._16i ._2Ch{position:relative;display:flex;width:100%;padding:0 20px;justify-content:center;height:28px;align-items:center}._16i ._2Ch.-IK{justify-content:flex-start}._16i ._2Ch._2_I{justify-content:flex-end}._16i .alf{height:8px;width:8px;border-radius:50%;background:hsla(0,0%,100%,.3);margin:0 4px;display:inline-block}._16i .alf._3Zx{background:hsla(0,0%,100%,.6)}", ""]);
// Exports
exports.locals = {
	"carousel": "_16i",
	"scene": "_3f-",
	"slide": "_1P3",
	"active": "_3Zx",
	"left": "_1BG",
	"right": "_1wv",
	"dotsContainer": "_2Ch",
	"dotsAtLeft": "-IK",
	"dotsAtRight": "_2_I",
	"dot": "alf"
};
module.exports = exports;
