var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useRef } from 'react';
const Search = forwardRef((_a, ref) => {
    var { placeholder, invalid, fluid, onChange, onSelect, dataset, className } = _a, props = __rest(_a, ["placeholder", "invalid", "fluid", "onChange", "onSelect", "dataset", "className"]);
    const wcRef = useRef();
    useEffect(() => {
        if (ref && !ref.current) {
            // eslint-disable-next-line no-param-reassign
            ref.current = wcRef.current;
        }
        wcRef.current.addEventListener('search:change', onChange);
        wcRef.current.addEventListener('search:select', onSelect);
        return () => {
            wcRef.current.removeEventListener('search:change', onChange);
            wcRef.current.removeEventListener('search:select', onSelect);
        };
    }, []);
    useEffect(() => {
        ;
        wcRef.current.data = dataset;
    }, [dataset]);
    return React.createElement("puk-search", Object.assign({ ref: wcRef, placeholder: placeholder, invalid: invalid, fluid: fluid, customclass: className }, props));
});
Search.defaultProps = {
    dataset: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange() { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSelect() { }
};
export { Search };
