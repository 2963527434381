var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, createRef, useEffect } from 'react';
export const TextField = forwardRef((_a, ref) => {
    var { raised, invalid, small, big, fluid, iconAtRight, loading, clearable, onClear, icon, className } = _a, nativeProps = __rest(_a, ["raised", "invalid", "small", "big", "fluid", "iconAtRight", "loading", "clearable", "onClear", "icon", "className"]);
    const wrapperRef = createRef();
    const inputRef = createRef();
    useEffect(function () {
        if (ref && typeof ref === 'object') {
            // eslint-disable-next-line no-param-reassign
            ref.current = inputRef.current;
        }
        if (ref && typeof ref === 'function') {
            ref(inputRef.current);
        }
        wrapperRef.current.addEventListener('clear', onClear);
        return function () {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('clear', onClear);
            }
        };
    }, []);
    return (React.createElement("puk-textfield", { raised: raised, invalid: invalid, small: small, big: big, fluid: fluid, iconAtRight: iconAtRight, loading: loading, clearable: clearable, customclass: className, ref: wrapperRef },
        icon ? React.cloneElement(icon, { slot: 'icon' }) : null,
        React.createElement("input", Object.assign({ slot: "input" }, nativeProps, { ref: inputRef }))));
});
TextField.defaultProps = {
    raised: false,
    invalid: false,
    big: false,
    fluid: false,
    iconAtRight: false,
    loading: false,
    clearable: false,
    onClear: () => {
        /* IMPLEMENTED BY USER */
    },
    icon: null
};
