var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useEffect } from 'react';
const AutoComplete = (_a) => {
    var { raised, invalid, small, big, fluid, placeholder, options, icon, className, onChange } = _a, nativeProps = __rest(_a, ["raised", "invalid", "small", "big", "fluid", "placeholder", "options", "icon", "className", "onChange"]);
    const ref = createRef();
    const selectRef = createRef();
    useEffect(() => {
        ref.current.addEventListener('autocomplete:change', onChange);
        return () => {
            if (ref.current) {
                ref.current.removeEventListener('autocomplete:change', onChange);
            }
        };
    });
    return (React.createElement("puk-autocomplete", { raised: raised, invalid: invalid, small: small, big: big, fluid: fluid, placeholder: placeholder, class: className, ref: ref },
        icon,
        React.createElement("select", Object.assign({ slot: "select", ref: selectRef }, nativeProps, { onChange: () => { } }), options.map(o => (React.createElement("option", { key: o.value, value: o.value }, o.label))))));
};
AutoComplete.defaultProps = {
    big: false,
    fluid: false,
    icon: null,
    invalid: false,
    options: [],
    placeholder: '',
    raised: false,
    small: false,
    value: '',
    onChange: console.log
};
export { AutoComplete };
