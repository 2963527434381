var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { createRef, useEffect, useRef, memo } from 'react';
import Hammer from 'hammerjs';
import styles from '../../../core/src/components/carousel/carousel.old.scss';
// eslint-disable-next-line react/prop-types
const Carousel = memo((_a) => {
    var { mode, waitFor, children } = _a, props = __rest(_a, ["mode", "waitFor", "children"]);
    const wrapper = createRef();
    const scene = createRef();
    const refs = useRef([]);
    const dots = useRef([]);
    const index = useRef(0);
    const lastDirection = useRef('right');
    const binded = useRef(false);
    const mg = useRef();
    const mounted = useRef(false);
    const animationStarted = useRef(null);
    const dimensions = useRef({});
    const updateActiveIndex = () => {
        dots.current.forEach((dot, i) => {
            if (i === index.current) {
                dot.current.classList.add(styles.active);
            }
            else {
                dot.current.classList.remove(styles.active);
            }
        });
    };
    /**
     * Move the last slide to the beginning
     * This makes the effect of move to the right
     */
    const toRight = () => {
        if (!scene.current ||
            refs.current.some((r) => r.current === null)) {
            return;
        }
        const active = refs.current[1];
        const newActive = refs.current[0];
        const [last] = refs.current.splice(refs.current.length - 1, 1);
        const first = refs.current[0];
        refs.current.splice(0, 0, last);
        scene.current.insertBefore(last.current, first.current);
        active.current.classList.remove(styles.active);
        newActive.current.classList.add(styles.active);
        // updates the index to repaint dots
        index.current = Number(newActive.current.dataset.index);
        if (lastDirection.current === 'left') {
            // updates last direction
            lastDirection.current = 'right';
            updateActiveIndex();
            setTimeout(() => {
                toRight();
            }, 10);
        }
        else {
            // updates last direction
            lastDirection.current = 'right';
            updateActiveIndex();
        }
    };
    /**
     * Move the first slide to the end
     * This makes the effect of move to the left
     */
    const toLeft = () => {
        if (!scene.current ||
            refs.current.some((r) => r.current === null)) {
            return;
        }
        const active = refs.current[0];
        const newActive = refs.current[1];
        const [first] = refs.current.splice(0, 1);
        const last = refs.current[refs.current.length - 1];
        refs.current.push(first);
        scene.current.appendChild(last.current);
        active.current.classList.remove(styles.active);
        newActive.current.classList.add(styles.active);
        // updates the index to repaint dots
        index.current = Number(newActive.current.dataset.index);
        if (lastDirection.current === 'right') {
            // updates last direction
            lastDirection.current = 'left';
            updateActiveIndex();
            setTimeout(() => {
                toLeft();
            }, 10);
        }
        else {
            // updates last direction
            lastDirection.current = 'left';
            updateActiveIndex();
        }
    };
    const enableTouch = () => {
        if (binded.current === false) {
            // eslint-disable-next-line no-underscore-dangle
            const _mg = mg.current;
            _mg.on('swipeleft', toLeft);
            _mg.on('swiperight', toRight);
            binded.current = true;
        }
    };
    const applyDimensions = () => {
        const { width, height } = dimensions.current;
        scene.current.style.width = `${width}px`;
        scene.current.style.height = `${height}px`;
        wrapper.current.style.width = `${width}px`;
        wrapper.current.style.height = `calc(${height}px + 28px)`;
    };
    const saveDimensions = () => {
        if (refs.current.length) {
            let maxWidth = 0;
            let maxHeight = 0;
            refs.current.forEach((ref) => {
                if (!ref.current) {
                    return;
                }
                const slide = ref.current;
                const { width, height } = slide.getBoundingClientRect();
                if (width > maxWidth) {
                    maxWidth = width;
                }
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });
            dimensions.current = { width: maxWidth, height: maxHeight };
            applyDimensions();
        }
    };
    const reorderByFirstTime = () => {
        if (!refs.current.length) {
            return;
        }
        const [last] = refs.current.splice(refs.current.length - 1, 1);
        const first = refs.current[0];
        refs.current.splice(0, 0, last);
        scene.current.insertBefore(last.current, first.current);
        mounted.current = true;
    };
    useEffect(() => {
        // console.log('%c Reorder by first time', 'color: #09c; font-weight: bold')
        reorderByFirstTime();
        setTimeout(function () {
            saveDimensions();
        }, 500);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        init();
    }, []);
    const init = function () {
        // console.log('%c Updated', 'color: #00af3f; font-weight: bold')
        if (!mg.current) {
            mg.current = Hammer(scene.current);
        }
        enableTouch();
        index.current = 0;
        if (mode === 'auto') {
            clearInterval(animationStarted.current);
            animationStarted.current = setInterval(() => {
                toLeft();
            }, waitFor);
        }
    };
    return (React.createElement("section", Object.assign({ className: styles.carousel }, props, { ref: wrapper }),
        React.createElement("i", { className: styles.left, onClick: toLeft }),
        React.createElement("i", { className: styles.right, onClick: toRight }),
        React.createElement("article", { className: styles.scene, ref: scene }, React.Children.map(children, (child, i) => {
            const ref = createRef();
            const clone = React.cloneElement(child, {
                className: `${child.props.className} ${styles.slide} ${i === 0 ? styles.active : ''}`,
                'data-index': i,
                key: Math.floor(1e7 * Math.random()),
                ref
            });
            if (refs.current.length === React.Children.count(children)) {
                refs.current.splice(i, 1, ref);
            }
            else {
                refs.current.push(ref);
            }
            return clone;
        })),
        React.createElement("footer", { className: styles.dotsContainer }, React.Children.map(children, (_, i) => {
            const ref = createRef();
            const classes = [styles.dot];
            if (i === 0) {
                classes.push(styles.active);
            }
            const dot = (React.createElement("span", { className: classes.join(' '), ref: ref }));
            if (dots.current.length === React.Children.count(children)) {
                dots.current.splice(i, 1, ref);
            }
            else {
                dots.current.push(ref);
            }
            return dot;
        }))));
});
Carousel.defaultProps = {
    children: [],
    mode: 'manual',
    waitFor: 3500
};
export { Carousel };
