import {
    CARD_CVV,
    CARD_EXPIRY,
    CARD_NUMBER,
    CHANNEL_VISA,
    EVENTS_FORM_VISA,
    FONT_FORM_URL_VISA,
    LANGUAGE_VISA,
    STYLES_FORM_VISA,
    VALIDATIONS_FORM_VISA,
} from "../../constants/visa-form";

import {ENV} from "../../constants/enviroment";

export const setFormConfiguration = ({sessionToken, purchaseNumber, amount}) => {
    const configuration = {
        sessionkey: sessionToken,
        channel: CHANNEL_VISA,
        merchantid: ENV.MERCHANT_ID,
        purchasenumber: purchaseNumber,
        amount,
        callbackurl: "",
        language: LANGUAGE_VISA,
        font: FONT_FORM_URL_VISA,
    };

    window.payform.setConfiguration(configuration);
};

export const createCardNumberInput = (setQuotes, onChange) => {
    if (!window.cardNumber) {
        window.cardNumber = window.payform.createElement(
            CARD_NUMBER.name,
            {
                style: STYLES_FORM_VISA,
                placeholder: CARD_NUMBER.placeholder,
            },
            CARD_NUMBER.id
        );
        window.cardNumber.then((element) => {
            element.on(EVENTS_FORM_VISA.installments, (data) => {
                if (data) setQuotes(data);
            });

            element.on(EVENTS_FORM_VISA.change, (validations) => {
                const validation = validations.find(
                    (validation) =>
                        validation.code === VALIDATIONS_FORM_VISA.invalidCardNumber
                );
                onChange(validation);
            });
        });
    }
};

export const createCvvInput = (onChange) => {
    if (!window.cardCvv) {
        window.cardCvv = window.payform.createElement(
            CARD_CVV.name,
            {
                style: STYLES_FORM_VISA,
                placeholder: CARD_CVV.placeholder,
            },
            CARD_CVV.id
        );
        window.cardCvv.then((element) => {
            element.on(EVENTS_FORM_VISA.change, (validations) => {
                const validation = validations.find(
                    (validation) =>
                        validation.code === VALIDATIONS_FORM_VISA.invalidCardCvv
                );
                onChange(validation);
            });
        });
    }
};

export const createExpirationDateInput = (onChange) => {
    if (!window.cardExpiry) {
        window.cardExpiry = window.payform.createElement(
            CARD_EXPIRY.name,
            {
                style: STYLES_FORM_VISA,
                placeholder: CARD_EXPIRY.placeholder,
            },
            CARD_EXPIRY.id
        );

        window.cardExpiry.then((element) => {
            element.on(EVENTS_FORM_VISA.change, (validations) => {
                const validation = validations.find(
                    (validation) =>
                        validation.code === VALIDATIONS_FORM_VISA.invalidCardExpiry
                );
                onChange(validation);
            });
        });
    }
};

export const createTransactionToken = (paymentData, pay, catchError) => {
    window.payform
        .createToken([window.cardNumber, window.cardExpiry, window.cardCvv], paymentData)
        .then(({transactionToken}) => pay(transactionToken))
        .catch((error) => catchError("Complete todos los campos correctamente"));
};
