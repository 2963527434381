import React from "react";
import iconInformacionAsset from "../../assets/images/icons/ic-information-circle.svg";

const ToolTipMessage = (props) => {
    return (
        <div style={{display: "flex", flexDirection: "row", height: "100%"}}>
            <span className="mr-1">{props.title}</span>
            <div className="tooltip">
                <img src={iconInformacionAsset} height={16} alt="" />
                <div className="tooltip-content">{props.children}</div>
            </div>
        </div>
    );
};
export default function TableCell({header, style, children, isVisibleTooltip}) {
    if (children === "Estado de Pago" && isVisibleTooltip) {
        return (
            <th>
                <ToolTipMessage title={children}>
                    <p>
                        El estado "Aplicado" {"\n"} y "Anulado" se actualiza a las:{"\n"}
                        <span>05:00 | 12:00 | 16:00</span> hrs
                    </p>
                </ToolTipMessage>
            </th>
        );
    }
    if (children === "Estado de póliza" && isVisibleTooltip) {
        return (
            <th>
                <ToolTipMessage title={children}>
                    <p>
                        Los estados se actualizan {"\n"} a las:{"\n"}
                        <span>05:00 | 12:00 | 16:00</span> hrs
                    </p>
                </ToolTipMessage>
            </th>
        );
    }

    return header ? <th style={style}>{children}</th> : <td style={style}>{children}</td>;
}
