export default {
    uiLoading: { status: false, message: "" },
    loading: false,
    error: null,
    policies: [],
    totalPayment: 0,
    creditLine: null,
    actions: { applyFilter: false },
    pagination: {
        currentPage: 0,
        itemsPerPage: 0,
        totalPages: 0,
        totalElements: 0,
        pageToGo: 0,
        isPagination: true
    }
};
