import { Redirect, Route } from "react-router-dom";
import { useAuth, useOauth } from "../../hooks";

import { ENV } from "../../constants/enviroment";
import { LoadingOverlay } from "../../components";
import React from "react";

export default function PrivateRoutes({ component: Component, ...rest }) {
    const { isAuth } = useAuth();
    const done = useOauth(ENV.OAUTH_UX_RESOURCE);

    return done ? (
        <Route
            {...rest}
            component={(props) =>
                isAuth() ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    ) : (
        <LoadingOverlay show />
    );
}
