import Api from "./api";
import { ENV } from "../constants/enviroment";

export default class AuthApi extends Api {
    static validateSsoToken(ssoToken) {
        return super.post(`${ENV.URL_MS_SP_ASEGURA_SESION}/sesiones/validar-sso-token`, {
            isPublicRequest: false,
            customHeader: {
                ...Api.getHeaderProductoSoatSP(),
            },
            data: JSON.stringify({
                ssoToken,
            }),
        });
    }

    static validateIdToken(idToken) {
        return super.post(`${ENV.URL_MS_SP_ASEGURA_SESION}/sesiones/validar-id-token`, {
            isPublicRequest: false,
            customHeader: {
                ...Api.getHeaderProductoSoatSP(),
            },
            data: JSON.stringify({
                idToken
            }),
        });
    }
}
