import {useEffect, useState} from "react";

import {Auth} from "@pacificoseguros/tools";
import {ENV} from "../constants/enviroment";

const useOauth = (resource) => {
    const [done, setDone] = useState(false);

    useEffect(() => {
        Auth.initialize({
            appId: resource,
            url: ENV.OAUTH_URL,
            storage: sessionStorage,
        }).then(() => {
            setDone(true);
        });
    }, [resource]);

    return done;
};
export default useOauth;
