import {
    AUTHORIZE_TRANSACTION_REQUEST,
    AUTHORIZE_TRANSACTION_RESPONSE,
    CLEAR_SESSION_PAYMENT,
    GET_CODE_PAGO_EFECTIVO_REQUEST,
    GET_CODE_PAGO_EFECTIVO_RESPONSE,
    GET_SESSION_VISA_REQUEST,
    GET_SESSION_VISA_RESPONSE,
} from "./payment-form.constants";

import {createActions} from "redux-actions";

const actionsCreator = createActions(
    {},
    GET_SESSION_VISA_REQUEST,
    GET_SESSION_VISA_RESPONSE,
    GET_CODE_PAGO_EFECTIVO_REQUEST,
    GET_CODE_PAGO_EFECTIVO_RESPONSE,
    AUTHORIZE_TRANSACTION_REQUEST,
    AUTHORIZE_TRANSACTION_RESPONSE,
    CLEAR_SESSION_PAYMENT
);
export const {
    getSessionVisaRequest,
    getSessionVisaResponse,
    getCodePagoEfectivoRequest,
    getCodePagoEfectivoResponse,
    authorizeTransactionRequest,
    authorizeTransactionResponse,
    clearSessionPayment,
} = actionsCreator;
