import {
    getUserAuth,
    isAuthenticated,
    removeUserAuth,
    setUserAuth,
} from "../libs/helpers/auth.helper";

import {setSsoToken} from "../redux/app/actions";
import {setUser} from "../redux/auth/auth.actions";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

const useAuth = () => {
    const dispatch = useDispatch();

    const getUser = useCallback(() => getUserAuth(), []);
    const isAuth = useCallback(() => isAuthenticated(), []);

    const authenticate = useCallback(
        (userData) => {
            dispatch(setUser(userData));
            setUserAuth(userData);
        },
        [dispatch]
    );

    const logout = useCallback(() => {
        dispatch(setSsoToken({ssoToken: null}));
        dispatch(setUser(null));
        removeUserAuth();
    }, [dispatch]);

    const verifySession = useCallback(() => {
        isAuth() ? authenticate(getUser()) : logout();
    }, [isAuth, authenticate, getUser, logout]);

    useEffect(() => {
        verifySession();
    }, [verifySession]);

    return {getUser, isAuth, authenticate, logout, verifySession};
};

export default useAuth;
