import React, {memo} from "react";

import Navbar from "./navbar/navbar";
import useAuth from "../../hooks";

const Layout = ({children}) => {
    const {isAuth, logout} = useAuth();

    return (
        <>
            <Navbar isAuth={isAuth} logout={logout} />
            {children}
        </>
    );
};

export default memo(Layout);
