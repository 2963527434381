import React, { useEffect, createRef } from 'react';
export const Tag = function ({ selectable, removable, selected, onTap, onRemove, className, children }) {
    const ref = createRef();
    useEffect(() => {
        if (selectable) {
            ref.current.addEventListener('tap', onTap);
        }
        if (removable) {
            ref.current.addEventListener('remove', onRemove);
        }
        return () => {
            if (ref.current) {
                if (selectable) {
                    ref.current.removeEventListener('tap', onTap);
                }
                if (removable) {
                    ref.current.removeEventListener('remove', onRemove);
                }
            }
        };
    }, []);
    return (React.createElement("puk-tag", { selectable: selectable, removable: removable, selected: selected, customclass: className, ref: ref }, children));
};
Tag.defaultProps = {
    selectable: false,
    removable: false,
    selected: false,
    onTap: () => { },
    onRemove: () => { }
};
