import "./spinner.scss";

import React, {memo} from "react";

import PropTypes from "prop-types";

const Spinner = ({visible, color, stroke, radius, ...rest}) => {
    if (!visible) return null;

    return (
        <div data-testid="spinner" {...rest}>
            <svg
                viewBox="0 0 50 50"
                className="spinner"
                style={{fontSize: `calc(${radius} * 1em)`}}
            >
                <circle
                    className="spinner__ring"
                    cx="25"
                    cy="25"
                    r="22.5"
                    style={{strokeWidth: stroke}}
                />
                <circle
                    className="spinner__line"
                    cx="25"
                    cy="25"
                    r="22.5"
                    style={{stroke: color, strokeWidth: stroke}}
                />
            </svg>
        </div>
    );
};

Spinner.propTypes = {
    visible: PropTypes.bool,
    color: PropTypes.string,
    stroke: PropTypes.number,
    radius: PropTypes.number,
};

Spinner.defaultProps = {
    visible: true,
    color: "#0099cc",
    stroke: 3,
    radius: 7,
};

export default memo(Spinner);
