import Api from "./api";
import {ENV} from "../constants/enviroment";
import {of} from "rxjs";

export default class CreditLineApi extends Api {
    static getCreditLine() {
        return super.get(`${ENV.URL_MS_UX_ACTUALIZA_LINEA_CREDITO}/lineas-de-credito`, {
            isPublicRequest: false,
            customHeader: {
                ...Api.getHeaderProductoSoatUX(),
            },
        });
    }

    static getCreditLineTemporal() {
        //return throwError(new Error("Error en la linea de credito"));
        return of({response: {initialCreditLine: 40000, availableCreditLine: 20000}});
    }
}
