import React, { createRef, useEffect } from 'react';
export const Slider = function ({ extension, min, max, value, onChange, className, }) {
    const ref = createRef();
    useEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('input', onChange);
        return () => {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('input', onChange);
        };
    }, []);
    return (React.createElement("puk-slider", { extension: extension, min: min, max: max, value: value, customclass: className, ref: ref }));
};
Slider.defaultProps = {
    extension: 0,
    min: 0,
    max: 1,
    value: 0,
};
