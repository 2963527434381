import {SET_SSO_TOKEN} from "./constants";
import {handleActions} from "redux-actions";
import initialState from "./state";

const AppReducer = handleActions(
    {
        [SET_SSO_TOKEN]: (state, {payload}) => ({...state, ssoToken: payload.ssoToken}),
    },
    initialState
);

export default AppReducer;
