export default {
    session: {
        loading: false,
        error: null,
        tokenSecurity: null,
        sessionToken: null,
        expirationSession: null,
        amount: 0,
        purchaseNumber: 0,
    },
    transaction: {
        loading: false,
        success: null,
        error: null,
        data: {
            actionCode: null,
            actionDescription: null,
            amount: 0,
            authorizationCode: null,
            cardNumber: null,
            currency: null,
            email: null,
            fullName: null,
            message: null,
            purchaseNumber: null,
            transactionDate: null,
            transactionId: null,
        },
    },
    pagoEfectivo: {
        loading: false,
        success: null,
        error: null,
        cipCode: null,
        cipUrl: null,
    },
};
