export const SUCCESS_MESSAGE = {
    icon: "success",
    title: "Operación completada",
    content: "Se ha realizado la operación correctamente",
};
export const ALERT_MESSAGE = {
    icon: "alert",
    title: "Advertencia",
    content: "Contenido en el mensaje de advertencia.",
};
export const ERROR_MESSAGE = {
    icon: "error",
    title: "Lo sentimos",
    content:
        "No hemos podido procesar tu solicitud debido a un problema en nuestro servidor. Por favor, inténtelo más tarde.",
};

export const ERROR_MESSAGE_INIT = {
    icon: "error",
    title: "Lo sentimos",
    content:
        "Ha ocurrido un error al intentar ingresar al portal. Contactarse con el administrador del sistema.",
};

export const ERROR_404 = {
    icon: "pc-error",
    title: "Página no encontrada",
    content: "La página a la que intenta acceder no se encuentra disponible o no existe.",
};

export const ERROR_VISA = {
    icon: "",
    title: "Servicios no disponibles",
    content: `Los servicios para procesar los pagos correspondientes de las 
    pólizas no se encuentra disponibles en estos momentos. Por favor, inténtelo más tarde.`,
};
