/* eslint-disable react/prop-types */
import React, { useEffect, createRef } from 'react';
export const CheckboxGroup = function ({ children, handleChange }) {
    const ref = createRef();
    useEffect(() => {
        ref.current.addEventListener('checkboxgroup:change', handleChange);
        return function () {
            if (ref.current) {
                ref.current.removeEventListener('checkboxgroup:change', handleChange);
            }
        };
    }, []);
    return React.createElement("puk-checkbox-group", { ref: ref }, children);
};
CheckboxGroup.defaultProps = {
    handleChange: () => { },
    children: []
};
