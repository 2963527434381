import React, {memo} from "react";

import {Modal} from "@pacificoseguros/pixie/react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

const CustomModal = ({children, isOpen, onClose}) => {
    return (
        isOpen &&
        ReactDOM.createPortal(
            <Modal
                data-testid="custom-modal"
                rounded="true"
                open={isOpen}
                onClose={onClose}
            >
                {children}
            </Modal>,
            document.getElementById("modal-root")
        )
    );
};

CustomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default memo(CustomModal);
