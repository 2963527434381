import "./credit-line.scss";

import React, {memo} from "react";

import PropTypes from "prop-types";
import useCreditLine from "../../hooks/use-credit-line";

const CreditLine = ({creditLine}) => {
    const {
        statusColor,
        statusMessage,
        availableCreditLine,
        initialCreditLine,
    } = useCreditLine(creditLine);

    return (
        <div className="credit-line" data-testid="credit-line">
            <div className="credit-line__credit--total">
                <h4 className={`${statusColor}-color`}>S/ {availableCreditLine}</h4>
                <h4 className="default-color">S/ {initialCreditLine}</h4>
            </div>
            <div className="credit-line__line">
                <div
                    id="available-line"
                    className={`credit-line__line--${statusColor}`}
                ></div>
            </div>
            <div className="credit-line__credit--description">
                <h4 className={`${statusColor}-color`}>{statusMessage}</h4>
                <h4 className="default-color">Linea de Crédito</h4>
            </div>
        </div>
    );
};

CreditLine.propTypes = {
    creditLine: PropTypes.object.isRequired,
};

export default memo(CreditLine);
