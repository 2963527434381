export const CHANNEL_VISA = "web";
export const LANGUAGE_VISA = "es";
export const FONT_FORM_URL_VISA =
    "https://fonts.googleapis.com/css?family=Montserrat:400&display=swap";
export const STYLES_FORM_VISA = {
    base: {
        color: "black",
        margin: "0",
        fontFamily: "'FocoRegular', sans-serif",
        fontSmoothing: "antialiased",
        placeholder: {
            color: "#999999",
        },
        autofill: {
            color: "#e39f48",
        },
    },
    invalid: {
        color: "#E25950",
        "::placeholder": {
            color: "#FFCCA5",
        },
    },
};
export const CARD_NUMBER = {
    id: "txtNumeroTarjeta",
    name: "card-number",
    placeholder: "Número de Tarjeta",
};
export const CARD_CVV = {
    id: "txtCvv",
    name: "card-cvc",
    placeholder: "CVV",
};
export const CARD_EXPIRY = {
    id: "txtFechaVencimiento",
    name: "card-expiry",
    placeholder: "MM/AA",
};
export const EVENTS_FORM_VISA = {
    change: "change",
    installments: "installments",
};
export const VALIDATIONS_FORM_VISA = {
    invalidCardNumber: "invalid_number",
    invalidCardCvv: "invalid_cvc",
    invalidCardExpiry: "invalid_expiry",
};
