var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
export const Checkbox = (_a) => {
    var { className, children, fluid, handleChange = () => { }, checked } = _a, props = __rest(_a, ["className", "children", "fluid", "handleChange", "checked"]);
    const ref = useRef();
    useEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener("checkbox:change", handleChange);
        return () => {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("checkbox:change", handleChange);
        };
    }, []);
    return (React.createElement("puk-checkbox", Object.assign({ ref: ref, customclass: className, fluid: fluid, checked: checked }, props), children));
};
Checkbox.defaultProps = {
    fluid: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange() { },
    customClass: '',
};
