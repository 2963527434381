var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { forwardRef, createRef, useEffect } from 'react';
export const Dropdown = forwardRef((_a, ref) => {
    var { raised, invalid, small, big, fluid, placeholder, options, 
    // onChoose,
    hideNativeSelect, icon, className } = _a, nativeProps = __rest(_a, ["raised", "invalid", "small", "big", "fluid", "placeholder", "options", "hideNativeSelect", "icon", "className"]);
    const _ref = createRef();
    const _selectRef = createRef();
    useEffect(() => {
        if (ref && !ref.current) {
            if (typeof ref === 'object') {
                // eslint-disable-next-line no-param-reassign
                ref.current = _selectRef.current;
            }
            if (typeof ref === 'function') {
                ref(_selectRef.current);
            }
        }
    }, []);
    return (React.createElement("puk-dropdown", { ref: _ref, raised: raised, big: big, small: small, fluid: fluid, placeholder: placeholder, hideNativeSelect: hideNativeSelect, value: nativeProps.value, customclass: className },
        icon ? React.cloneElement(icon, { slot: 'icon' }) : null,
        React.createElement("select", Object.assign({ slot: "select", ref: _selectRef }, nativeProps),
            React.createElement("option", { value: "", disabled: true }, placeholder),
            options.map((option) => (React.createElement("option", { key: option.value, value: option.value }, option.label))))));
});
Dropdown.defaultProps = {
    raised: false,
    invalid: false,
    small: false,
    big: false,
    fluid: false,
    placeholder: '',
    options: [],
    value: '',
    // onChoose: () => {},
    hideNativeSelect: true,
    icon: null,
};
