var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React, { useEffect, createRef } from 'react';
const Modal = (_a) => {
    var { onOpen, onClose, open, children, className } = _a, props = __rest(_a, ["onOpen", "onClose", "open", "children", "className"]);
    const ref = createRef();
    useEffect(function () {
        ref.current.addEventListener('modal:open', onOpen);
        ref.current.addEventListener('modal:close', onClose);
        return function () {
            if (ref.current) {
                ref.current.removeEventListener('modal:open', onOpen);
                ref.current.removeEventListener('modal:close', onClose);
            }
        };
    }, []);
    return (React.createElement("puk-modal", Object.assign({ ref: ref }, props, { customclass: className, open: open }), children));
};
Modal.defaultProps = {
    onOpen: () => { },
    onClose: () => { },
    open: false,
};
export { Modal };
