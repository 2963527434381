import "./loading.scss";

import React, {memo} from "react";

import PropTypes from "prop-types";
import Spinner from "../spinner/spinner";

const Loading = ({show, message, radius, color}) => {
    return (
        show && (
            <div data-testid="loading" className="loading">
                <Spinner radius={radius} color={color} visible stroke={3} />
                <h3 className="mt-3 text-center">{message}</h3>
            </div>
        )
    );
};

Loading.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
    radius: PropTypes.number,
    color: PropTypes.string,
};

Loading.defaultProps = {
    show: false,
    message: "",
    radius: 5,
    color: "#0099cc",
};

export default memo(Loading);
