import "./loading-overlay.scss";

import React, {memo} from "react";

import PropTypes from "prop-types";
import Spinner from "../spinner/spinner";

const LoadingOverlay = ({show, message}) => {
    return (
        <>
            {show && (
                <div data-testid="loading-overlay" className="loading-overlay">
                    <div className="loading-overlay__container">
                        <Spinner stroke={3} />
                    </div>

                    <h3 className="text-center">{message}</h3>
                </div>
            )}
        </>
    );
};

LoadingOverlay.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
};

LoadingOverlay.defaultProps = {
    show: false,
    message: "Cargando...",
};

export default memo(LoadingOverlay);
