import {
    CLEAR_ERROR_PAYMENTS,
    GET_CREDIT_LINE_REQUEST,
    GET_CREDIT_LINE_RESPONSE,
    GET_POLICIES_REQUEST,
    GET_POLICIES_RESPONSE,
    SET_TOTAL_PAYMENT,
    SET_UI_LOADING,
} from "./payments.constants";

import { handleActions } from "redux-actions";
import initialState from "./payments.state";

const PaymentsReducer = handleActions(
    {
        [GET_POLICIES_REQUEST]: (state) => ({
            ...state,
            loading: true,
            error: null
        }),
        [GET_POLICIES_RESPONSE]: {
            next: (state, { payload }) => ({
                ...state,
                loading: false,
                policies: payload.policies,
                pagination: { ...state.pagination, ...payload.pagination },
                uiLoading: payload.uiLoading,
                actions: { ...state.actions, ...payload.actions }
            }),
            throw: (state, { payload }) => ({
                ...state,
                loading: false,
                error: payload.response,
            }),
        },
        [GET_CREDIT_LINE_REQUEST]: (state) => ({
            ...state,
        }),
        [GET_CREDIT_LINE_RESPONSE]: {
            next: (state, { payload }) => ({
                ...state,
                creditLine: payload,
            }),
            throw: (state, { payload }) => ({
                ...state,
                creditLine: null,
            }),
        },
        [SET_TOTAL_PAYMENT]: (state, { payload }) => ({ ...state, totalPayment: payload }),
        [CLEAR_ERROR_PAYMENTS]: (state) => ({
            ...state,
            loading: false,
            error: null,
        }),
        [SET_UI_LOADING]: (state, { payload }) => ({
            ...state,
            uiLoading: {
                status: !state.uiLoading.status,
                message: payload,
            },
        }),
    },
    initialState
);

export default PaymentsReducer;
