import {
    POST_VALIDATE_SSO_TOKEN_REQUEST,
    POST_VALIDATE_SSO_TOKEN_RESPONSE,
    SET_USER,
} from "./auth.constants";

import {createActions} from "redux-actions";

const actionsCreator = createActions(
    {},
    POST_VALIDATE_SSO_TOKEN_REQUEST,
    POST_VALIDATE_SSO_TOKEN_RESPONSE,
    SET_USER
);
export const {
    setUser,
    postValidateSsoTokenRequest,
    postValidateSsoTokenResponse,
} = actionsCreator;
