var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
import React from 'react';
const Card = (_a) => {
    var { distance, fluid, theme, className, children } = _a, props = __rest(_a, ["distance", "fluid", "theme", "className", "children"]);
    return (React.createElement("puk-card", Object.assign({ fluid: fluid, distance: distance, theme: theme, customclass: className }, props), children));
};
Card.defaultProps = {
    distance: 1,
    fluid: false,
    theme: 'basic',
};
export { Card };
