import Api from "./api";
import { ENV } from "../constants/enviroment";

export default class PoliciesApi extends Api {

    static getPolicies(params) {

        return super.get(`${ENV.URL_MS_UX_GESTION_POLIZAS}/policies`, {
            params,
            isPublicRequest: false,
            customHeader: {
                ...Api.getHeaderProductoSoatUX(),
            },
        });
    }

    static getTemporalPolicies() {
        return super.get(`${ENV.URL_MS_NE_CONSULTA_POLIZAS}/polizas/pruebaLista`, {
            isPublicRequest: false,
            customHeader: {
                ...Api.getHeaderProductoSoatUX(),
            },
        });
    }
}
