import {
    AUTHORIZE_TRANSACTION_REQUEST,
    GET_CODE_PAGO_EFECTIVO_REQUEST,
    GET_SESSION_VISA_REQUEST,
} from "./payment-form.constants";
import {
    authorizeTransactionResponse,
    getCodePagoEfectivoResponse,
    getSessionVisaResponse,
} from "./payment-form.actions";
import {catchError, switchMap} from "rxjs/operators";
import {combineEpics, ofType} from "redux-observable";

import PaymentApi from "../../api/payment.api";
import {of} from "rxjs";
import {setFormConfiguration} from "../../libs/helpers/payment.helper";

export const getSessionVisaRequestEpic = (action$) =>
    action$.pipe(
        ofType(GET_SESSION_VISA_REQUEST),
        switchMap(({payload}) =>
            PaymentApi.getSessionToken(payload).pipe(
                switchMap(({response}) => {
                    setFormConfiguration(response);
                    return of(getSessionVisaResponse(response));
                }),
                catchError((error) => {
                    return of(getSessionVisaResponse(error));
                })
            )
        )
    );

export const authorizeTransactionRequestEpic = (action$) =>
    action$.pipe(
        ofType(AUTHORIZE_TRANSACTION_REQUEST),
        switchMap(({payload}) =>
            PaymentApi.validateTransaction(payload.order).pipe(
                switchMap(({response}) => {
                    payload.closeForm();
                    return of(authorizeTransactionResponse(response));
                }),
                catchError((error) => {
                    payload.closeForm();
                    return of(authorizeTransactionResponse(error));
                })
            )
        )
    );

export const getCodePagoEfectivoEpic = (action$) =>
    action$.pipe(
        ofType(GET_CODE_PAGO_EFECTIVO_REQUEST),
        switchMap(({payload}) =>
            PaymentApi.getCipCode(payload).pipe(
                switchMap((response) => {
                    return of(getCodePagoEfectivoResponse(response));
                }),
                catchError((error) => {
                    return of(getCodePagoEfectivoResponse(error));
                })
            )
        )
    );

export const PaymentFormEpics = combineEpics(
    getSessionVisaRequestEpic,
    authorizeTransactionRequestEpic,
    getCodePagoEfectivoEpic
);
