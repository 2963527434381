var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, createRef } from 'react';
const Accordion = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return React.createElement("puk-accordion", Object.assign({}, props), children);
};
const AccordionItem = (_a) => {
    var { children, expanded, onExpand, onCollapse, className } = _a, props = __rest(_a, ["children", "expanded", "onExpand", "onCollapse", "className"]);
    const wcRef = createRef();
    useEffect(() => {
        wcRef.current.addEventListener('accordion:expand', onExpand);
        wcRef.current.addEventListener('accordion:collapse', onCollapse);
        return function () {
            if (wcRef.current) {
                wcRef.current.removeEventListener('accordion:expand', onExpand);
                wcRef.current.removeEventListener('accordion:collapse', onCollapse);
            }
        };
    }, []);
    return (React.createElement("puk-accordion-item", Object.assign({}, props, { customclass: className, ref: wcRef, expanded: expanded }), children));
};
AccordionItem.defaultProps = {
    expanded: false,
    className: '',
    onExpand: () => { },
    onCollapse: () => { },
};
export { Accordion, AccordionItem };
