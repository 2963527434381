import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Layout from "./../shared/layout";
import {LoadingOverlay} from "../../components";
import PrivateRoutes from "./private.routes";
import useValidateToken from "./hooks/use-validate-token";

const Payments = lazy(() => import("./../payments/payments"));
const Init = lazy(() => import("./../init/init"));

export default function AppRoutes() {
    useValidateToken();

    return (
        <Layout>
            <Suspense fallback={<LoadingOverlay show />}>
                <Switch>
                    <PrivateRoutes exact path="/payments" component={Payments} />
                    <Route exact path="/" component={Init} />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </Layout>
    );
}
